import React from 'react'
import Typography from '@mui/material/Typography';
import { Card, Divider,Box,Stack,List,ListItem,ListItemText  } from '@mui/material';

export default function Detail() {
  return (
    <Card sx={{borderRadius:1}}  m={5}>
        <Box sx={{ p:5}}>
        <Typography variant='h4'> About Me</Typography>

        <Stack direction="row" spacing={10} sx={{ pt:5 }}>
            <Stack sx={{ pt:2 }}>
                <Typography variant='h6'>Full Name</Typography>
                <Typography variant='body2'>John Smith Doe</Typography>

            </Stack>

            <Stack sx={{ pt:2 }}>
                <Typography variant='h6'>Mobile</Typography>
                <Typography variant='body2'>(123) 456 7890</Typography>

            </Stack>

            <Stack sx={{ pt:2 }}>
                <Typography variant='h6'>Email</Typography>
                <Typography variant='body2'>johndeo@example.com</Typography>

            </Stack>

            <Stack sx={{ pt:2 }}>
                <Typography variant='h6'>Location</Typography>
                <Typography variant='body2'>Ethiopia</Typography>

            </Stack>

        </Stack>
        </Box>

        <Box sx={{ p:5}}>
            <Typography variant='body1' color="grey">
            Completed my graduation in Arts from the well known and renowned institution of India – SARDAR PATEL ARTS COLLEGE, BARODA in 2000-01, which was affiliated to M.S. University. I ranker in University exams from the same university from 1996-01.
<br/><br/>
Worked as Professor and Head of the department at Sarda Collage, Rajkot, Gujarat from 2003-2015
<br/><br/>
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </Typography>

        </Box>

        <Box sx={{ p:5}}>
            <Typography variant='h4'> Education </Typography>

            <List>
      <ListItem>
        <ListItemText primary="M.B.B.S.,Gujarat University, Ahmedabad,India." />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="M.S.,Gujarat University, Ahmedabad, India." />
      </ListItem>
      <ListItem >
        <ListItemText primary="SPINAL FELLOWSHIP Dr. John Adam, Allegimeines Krakenhaus, Schwerin, Germany." />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="Fellowship in Endoscopic Spine Surgery Phoenix, USA." />
      </ListItem>
    </List>
            

        </Box>


        <Box sx={{ p:5}}>
            <Typography variant='h4'> Experience </Typography>

            <List>
      <ListItem>
        <ListItemText primary="One year rotatory internship from April-2009 to march-2010 at B. J. Medical College, Ahmedabad.
Three year residency at V.S. General Hospital as a resident in orthopedics from April - 2008 to April - 2011." />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="I have worked as a part time physiotherapist in Apang manav mandal from 1st june 2004 to 31st jan 2005." />
      </ListItem>
      <ListItem >
        <ListItemText primary="Clinical and Research fellowship in Scoliosis at Shaurashtra University and Medical Centre (KUMC) , Krishna Hospital , Rajkot from April 2013 to June 2013." />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="2.5 Years Worked at Mahatma Gandhi General Hospital, Surendranagar.
" />
      </ListItem>
    </List>
            

        </Box>



        <Box sx={{ p:5}}>
            <Typography variant='h4'> Conferences, Cources & Workshop Attended
 </Typography>

            <List>
      <ListItem>
        <ListItemText primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
      </ListItem>
      <ListItem >
        <ListItemText primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
.
" />
      </ListItem>
    </List>
            

        </Box>
        </Card>
  )
}
