import React from 'react'
import Profile from './Profile'
import {Grid,Container} from '@mui/material';
import About from './About';
import Detail from './Detail';

// components
import Page from '../Page';

export default function Employee() {
  return (
 

<Page title="Project Details">
      <Container maxWidth={'lg'}>
<Grid container spacing={2}>
  <Grid item xs={4}>
  <Profile/><br/>
  <About/>
  </Grid>
  <Grid item xs={8}>
    <Detail/>
    
  </Grid>
  
</Grid>

        

</Container>
</Page>
  )
}
