import React from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Typography from '@mui/material/Typography';
import { Card, Divider } from '@mui/material';
import {Stack,LinearProgress} from '@mui/material';

export default function About() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <Card sx={{borderRadius:1}}  m={5}>
    <Box sx={{ width: '100%', typography: 'body1' }} >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="">
            <Tab label="About" value="1" />
            <Tab label="Skills" value="2" />
       
          </TabList>
        </Box>
        <TabPanel value="1">
                <Box>
                <Typography align='left'  variant="body2" color="black" component="p">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    </Typography>

                </Box>

                <Box sx={{ pt:3 }}>
                    <Typography variant='body2' color="grey.500">Email Adress</Typography>
                    <Typography variant='body1'> john@mail.com</Typography>
                    <Divider sx={{ pt:1 }}/>
                </Box>

                <Box sx={{ pt:3 }}>
                    <Typography variant='body2' color="grey.500">Phone</Typography>
                    <Typography variant='body1'> +91 1234567890</Typography>
                   
                </Box>
                

        </TabPanel>
        <TabPanel value="2">

        
                  <Stack spacing={1} sx={{ px: 1, my: 5 }}>
                    <Box sx={{ py:1 }}>
                    CSS
                    <LinearProgress
                      variant="determinate"
                      key=""
                      value="53"
                      color="secondary"
                      sx={{ height: 8, bgcolor: 'grey.50016', borderRadius: 8 }}
                    />
                    </Box>

                    <Box sx={{ py:1 }}>

HTML
                    <LinearProgress
                      variant="determinate"
                      key=""
                      value="53"
                      color="warning"
                      sx={{ height: 8, bgcolor: 'grey.50016', borderRadius: 8 }}
                    />
                    </Box>
                  </Stack>
                
        </TabPanel>
  
      </TabContext>
    </Box>
    </Card>
  )
}
